import React from "react";
import Pusher from "pusher-js";
import Main from "./pages/Main";
import "./assets/scss/base.scss";
import ReactDOM from "react-dom";
import Geocode from "react-geocode";
import {Provider} from "react-redux";
import {loadUser} from "./actions/auth";
import {PusherProvider} from "react-pusher-hoc";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./config/configureStore";
import {getNotification} from "./actions/notification";
import {BrowserRouter as Router} from "react-router-dom";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
Geocode.enableDebug();

const pusherClient = new Pusher("ff8dc06ce53831298a33", {
    cluster: "eu",
});

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
    store.dispatch(loadUser());
    store.dispatch(getNotification());
    ReactDOM.render(
        <Provider store={store}>
            <PusherProvider value={pusherClient}>
                <Router>
                    <Component/>
                </Router>
            </PusherProvider>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept("./pages/Main", () => {
        const NextApp = require("./pages/Main").default;
        renderApp(NextApp);
    });
}

serviceWorker.unregister();
